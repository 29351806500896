const firebaseConfig = {
    apiKey: "AIzaSyCiXqnON-5clWBQeRjpk36js46N4X4e5Mk",
    authDomain: "utb-site.firebaseapp.com",
    projectId: "utb-site",
    storageBucket: "utb-site.appspot.com",
    messagingSenderId: "230559684105",
    appId: "1:230559684105:web:c3c06d4d0fbcad834e0ad8",
    measurementId: "G-JKD3RX58V4"
  };

  export default firebaseConfig;