const characteristicNames = new Map([
	['width', 'Ширина'],
	['height', 'Висота'],
	['length', 'Довжина'],
	['heightAdjust', 'Регулювання висоти'],
	['maxLoad', 'Максимальне навантаження'],
	['tableWeight', 'Маса столу'],
	['towelHolder', 'Тримач рушників'],
	['wheelSystem', 'Колісна система'],
	['faceOpening', 'Лицьовий отвір'],
	['antiTrendelenburgPos', 'Положення анти-Тренделенбурга'],
	['backAngleAdjust', 'Регулювання кута нахилу спинки'],
	['headrestAngleAdjust', 'Регулювання кута нахилу підголівника'],
	['curbWeight', 'Вага'],
	['dripHanger', 'Стійка для крапельниць'],
	['footControl', 'Ножний пульт'],
	['footRest', 'Секція для ніг (збільшення довжини)'],
	['handles', 'Ручки'],
	['injectionSupport', "Підтримка ін'єкцій"],
	['trendelenburgPos', 'Положення Тренделенбурга'],
	['lengthWith', 'Довжина (без секції для ніг/ з секцією для ніг)'],
	['stepHeight', 'Висота кроку'],
	['mattressAndTotalLength', 'Довжина матраца / загальна'],
	['mattressAndTotalWidth', 'Ширина матраца / загальна'],
	['wheelDiameter', 'Діаметр колеса'],
	['sideRailings', 'Бічні перила'],
	['wheelLock', 'Центральний тормоз'],
	['oxygenHanger', 'Вішалка для кисневого балона'],
	['personalBasket', 'Кошик для особистих речей пацієнта'],
	['patientRestraints', 'Ремені для фіксації пацієнта'],
	['antibacterialPaintFrame', 'Каркас покритий антибактеріальною фарбою'],
	['mattressHandles', 'Матрац з ручками для перенесення пацієнтів'],
	['guideXRayCassette', 'Ніша для рентгенівської касети'],
	['seatAndTotalWidth', 'Ширина сидіння / загальна'],
	['depth', 'Глибина'],
	['seatHeight', 'Висота сидіння'],
	['headrestHeightAdjust', 'Регулювання висоти підголівника'],
	['armrestsHeightAdjust', 'Регулювання висоти підлокітників'],
	['bolster', 'Підголівник'],
	['totalUnfoldedLength', 'Загальна довжина в розкладеному вигляді'],
	['footrestAngleAdjust', 'Регулювання кута нахилу ножної секції'],
	['footrest', 'Секція для ніг (збільшення довжини)'],
	['heightWithHeadrest', 'Висота загальна / з підголовником'],
	[
		'totalUnfoldedLengthWithFootrest',
		'Загальна довжина в розкладеному стані з підставкою для ніг',
	],
	['eurorails', 'Єврорейки'],
	['flipTable', 'Перекидний стіл'],
	['toolTable', 'Інструментальний стіл'],
	['heightOnFeet', 'Висота на ніжках'],
	['heightOnWheels', 'Висота на колесах'],
	['base', 'Основа'],
	['seat', 'Сидіння'],
	['totalTableWidth', 'Загальна ширина столу на вибір'],
	['totalTableDepth', 'Загальна глибина столу на вибір'],
	['heightToTabletop', 'Висота до стільниці на вибір'],
	['maxLoadOnSideShelf', 'Максимальне навантаження на бічну полицю'],
	['lowerBerth', 'Нижня полиця'],
	['sideBerth', 'Бічна полиця'],
	['shelf', 'Шухляда'],
	['cabinet', 'Шафа'],
	['hanger', 'Вішалка'],
	['tableWeightWithMetal', 'Вага з металевими полицями'],
	['tableWeightWithGlass', 'Вага зі скляними полицями'],
	['wheelBrakes', 'Колеса з гальмом'],
	['metalShelves', 'Металеві полиці'],
	['glassShelves', 'Скляні полиці'],
	['chromeNickelShelves', 'Хромонікелеві полиці'],
	['maxLoadOnShelf', 'Максимальне навантаження на полицю'],
	['shelfSize', 'Розміри полиці'],
	['additionalShelf', 'Додаткова полиця'],
	['cableSupport', 'Підтримка кабелю'],
	['powerExtension', 'Подовжувач живлення'],
	['widthChoice', 'Ширина на вибір'],
	['tableTopAngleAdjust', 'Регулювання кута нахилу стільниці'],
	['manuallyArmrestAdjust', 'Підлокітники, що регулюються вручну'],
	['dropOutSideShelves', 'Опускання бічних перил'],
	['steeringFrame', 'Рама управління'],
	['electricTableTopAngleAdjust', 'Електричне регулювання кута стільниці'],
	['frameControl', 'Рама управління'],
	['chestAngleAdjust', 'Регулювання кута грудного відділу'],
	['lumbarAngleAdjust', 'Регулювання кута нахилу поперекового відділу'],
	['sideSwing', 'Бокове регулювання'],
	['handTraction', 'Ручна тяга'],
	['armrest', 'Підлокітники'],
	['headrestHeightAdjust', 'Регулювання висоти підголівника'],
	['headrestSwinging', 'Розширення підголівника'],
	['centralAngleAdjust', 'Регулювання кута центральної частини'],
	['heightChoice', 'Висота на вибір'],
	['sideAngleAdjust', 'Регулювання кута бічного вирізу'],
	['tableTopType', 'Тип стільниці'],
	[
		'electricTableTopAngleAdjust',
		'Електричне регулювання кута нахилу стільниці',
	],
	['electricVoltage', 'Електрична напруга'],
	['maxPower', 'Максимальна потужність'],
	['protectClass', 'Клас захисту'],
	['protectDegree', 'Ступінь захисту'],
	['longitudinalElevator', 'Поздовжній підйомник'],
	['transverseElevator', 'Поперечний підйомник'],
	['wheels', 'Колеса'],
	['footHandControl', 'Пульт ручний + ножний'],
	['waterproofMattress', 'Водонепроникний матрац'],
	['footControl2', 'Ножний пульт (2 шт.)'],
	['manualTableTopAngleAdjust', 'Ручне регулювання кута нахилу стільниці'],
	[
		'shelfWithDrawerAndBowl',
		'Полиця з висувним ящиком і чашею з нержавіючої сталі',
	],
	['gelHolder', 'Тримач гелю'],
	['heightWithoutMattress', 'Висота (без матраца)'],
	['recommendedMattress', 'Рекомендовані розміри матраца'],
	['backSegmentAngle', 'Кут підйому заднього сегмента'],
	['backSegmentAutoregression', 'Авторегресія заднього сегмента'],
	[
		'weightWithoutWithAdds',
		'Вага без додаткових опцій / з додатковими опціями',
	],
	['brightnessLux', 'Яскравість (люкс)'],
	['criRa', 'Індекс передавання кольору (Ra)'],
	['colorTemperature', 'Колірна температура (K)'],
	['dimmingRange', 'Діапазон затемнення (%)'],
	['ledCount', 'Кількість світлодіодів'],
	['sizeOfLightField', 'Розмір світлового поля Ø (см)'],
	['powerConsumption', 'Споживана потужність (Вт)'],
	['ledLifespanHours', 'Термін служби світлодіодів (год)'],
	['workingArea', 'Робоча площа (см)'],
	['lampBody', 'Корпус лампи Ø (см)'],
	['mobileBase', 'Мобільне кріплення'],
	['wallBase', 'Настінне кріплення'],
	['ceilingBase', 'Стельове кріплення'],
	['sectionCount', 'Кількість секцій'],
	['mattressBedLength', 'Довжина матрацного ложа'],
	['mattressBedWidth', 'Ширина матрацного ложа'],
	['spineSectionAngleAdjust', 'Регулювання кута нахилу спинної секції'],
	['hipSectionAngleAdjust', 'Регулювання кута нахилу тазостегнової секції'],
	[
		'mattressSectionAngleAdjust',
		'Регулювання матрацної основи по відношенню до горизонталі',
	],
	['controlPanelWithLockingKey', 'Пульт управління з блокуючим ключем'],
	['support', 'Гусак-підтримка'],
	['handrails', 'Поручні'],
	['overallWidthTable', 'Загальна ширина зі столиком'],
	['doubleCastor50', 'Подвійні колеса 50мм'],
	['diagnosAnaesthesia', 'Діагностика, анестезія'],
	['aspirationProbes', 'Аспірація, зонди'],
	['scalpelsKnives', 'Скальпелі, ножі'],
	['scissors', 'Ножиці'],
	['forceps', 'Щипці'],
	['dressingForceps', "Перев'язувальні щипці"],
	[
		'retractorsSelfRetrainRetractors',
		'Ретрактори',
	],
	['sutureInstrument', 'Шовні інструменти'],
	['hemostaticForceps', 'Кровоспинні щипці'],
	['removingBandagesInstruments', "Інструменти для зняття пов'язок"],
	['boneSurgeryInstruments', 'Інструменти для кісткової хірургії'],
	['microNeuroSurgeryInstruments', 'Інструменти для мікро та нейрохірургії'],
	[
		'thoraxLungSurgeryInstruments',
		'Інструменти для хірургії грудної клітки та легень',
	],
	['intestinalSurgeryInstruments', 'Інструменти для кишкової хірургії'],
	['urologyInstruments', 'Інструменти для урології'],
	['gynaecologyInstruments', 'Інструменти для гінекології'],
	['entInstruments', 'ЛОР інструменти'],
	['autopsyInstruments', 'Інструменти для розтину'],
	['videoCameras', 'Відеокамери'],
	['lightSources', 'Джерела світла'],
	['insufflators', 'Інсуффлятори'],
	['pumps', 'Помпи'],
	['aspiratorsIrrigators', 'Аспіратори/іригатори'],
	['electrocoagulators', 'Електрокоагулятори'],
	['recordingDevices', 'Записувальні пристрої'],
	['monitors', 'Монітори'],
	['applianceTrolleys', 'Візки для приладів'],
	['laparoscopes', 'Лапароскопи'],
	['trocars', 'Троакари'],
	['monopolarInstrument', 'Монополярний інструмент'],
	['bipolarInstrument', 'Біполярний інструмент'],
	['morcellator', 'Морцелятор'],
	['hysteroscopes', 'Гістрероскопи'],
	['monopolarResectoscopes', 'Монополярні резектоскопи'],
	['bipolarResectoscopes', 'Біполярні резектоскопи'],
	['hysteroscopeInstrument', 'Інструменти для гістероскопу'],
	['uterineManipulators', 'Маточні маніпулятори'],
	['cystoscopes', 'Цистоскопи'],
	['cystoscopyInstruments', 'Інструменти для цистоскопії'],
	['ureterorenoscopes', 'Уретерореноскопи'],
	['nephroscopes', 'Нефроскопи'],
	['shaver', 'Шейвер'],
	['arthroscopes', 'Артроскопи'],
	['motorizedDrillsSaws', 'Моторизовані дрилі та пилки'],
	['arthroscopicTubes', 'Тубуси артроскопічні'],
	['arthroscopyInstrument', 'Інструмент для артроскопії'],
	['thoracoscopes', 'Торакоскопи'],
	['flexibleTrocars', 'Гнучкі троакари'],
	['sinusoscopes', 'Синускопи'],
	['nasopharyngoscope', 'Назофарінгоскоп'],
	['sinusoscopyTools', 'Інструментарій для синускопії'],
	['optics', 'Оптика'],
	['scissors', 'Ножиці'],
	['clamps', 'Затискачі'],
	['dissector', 'Диссектора'],
	['needleHolders', 'Голкотримачі'],
	['pediatricCystoscopes', 'Педіатричні цистоскопи'],
	['pediatricResectoscopes', 'Педіатричні резектоскопи'],
	['clippers', 'Кліппатори'],
	['electrodes', 'Електроди'],
	['aspirationIrrigationTubes', 'Аспіраційні/іригаційні тубуси'],
	['instrumentary', 'Інструментарій'],
	['bipolarTweezers', 'Біполярні пінцети'],
]);

export default characteristicNames;
