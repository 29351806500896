const projectPhotos = [
	{
		src: 'images/aboutpage/project-photo-1.webp',
		alt: 'Фото проєкту 1',
	},
	{
		src: 'images/aboutpage/project-photo-2.webp',
		alt: 'Фото проєкту 2',
	},
	{
		src: 'images/aboutpage/project-photo-3.webp',
		alt: 'Фото проєкту 3',
	},
	{
		src: 'images/aboutpage/project-photo-4.webp',
		alt: 'Фото проєкту 4',
	},
	{
		src: 'images/aboutpage/project-photo-5.webp',
		alt: 'Фото проєкту 5',
	},
	{
		src: 'images/aboutpage/project-photo-6.webp',
		alt: 'Фото проєкту 6',
	},
	{
		src: 'images/aboutpage/project-photo-7.webp',
		alt: 'Фото проєкту 7',
	},
	{
		src: 'images/aboutpage/project-photo-8.webp',
		alt: 'Фото проєкту 8',
	},
	{
		src: 'images/aboutpage/project-photo-9.webp',
		alt: 'Фото проєкту 9',
	},
];

export default projectPhotos;
